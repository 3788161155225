const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'golf-and-fun-park',
	name: 'Golf & Fun Park',	
	website: 'https://golffunpark.dk',
	locations: [{
		id: 'vaeggerloese',
		cityId: 'vaeggerloese',
		opened: '20150401',
		closed: null,
		address: 'Bøtø Ringvej 2E, 4873 Væggerløse',
		coordinates: {lng: '11.94815309739427', lat: '54.68171183380252'}
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'house-of-spirits',
			name: {da: 'Åndernes Hus', en: 'House of Spirits'},
			languages: ['da'],
			teamSizeMin: 1,
			teamSizeMax: 6,
			difficulty: 80,
			locations: [{
				locationId: 'vaeggerloese',
				opened: '20240611',
				closed: null,
				rooms: 1
			}],
			
		}),
		Object.assign({}, roomTemplate, {
			id: 'da-vinci',
			name: {da: 'Da Vinci', en: 'Da Vinci'},
			languages: ['da'],
			teamSizeMin: 1,
			teamSizeMax: 6,
			difficulty: 70,
			locations: [{
				locationId: 'vaeggerloese',
				opened: '20240611',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'millions',
			name: {da: 'Millioner', en: 'Millions'},
			languages: ['da'],
			teamSizeMin: 1,
			teamSizeMax: 6,
			difficulty: 60,
			locations: [{
				locationId: 'vaeggerloese',
				opened: '20240611',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};