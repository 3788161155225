const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escape-fyn',
	name: 'Escape Fyn',	
	website: 'https://escapefyn.dk',
	locations: [{
		id: 'espe',
		cityId: 'espe',
		opened: '20241005',
		closed: null,
		address: 'Kastanievej 16, 5750 Espe',
		coordinates: {lng: '10.419460229169744', lat: '55.20968634545653'}
	}],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'virus',
			name: {da: 'Virus', en: 'Virus'},
			languages: ['da', 'en'],
			teamSizeMin: 2,
			teamSizeMax: 6,
			minimumAge: 12,
			minimumAgeNoAdult: 15,
			locations: [{
				locationId: 'espe',
				opened: '99999999',
				closed: null,
				rooms: 1
			}]
		})
	]
});


export {venueData};