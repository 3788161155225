const venuesAndRooms = [
	require('./rooms/adventure-house/venue-data').venueData,
	require('./rooms/amaze/venue-data').venueData,
	require('./rooms/ballerup-gokart-and-event-center/venue-data').venueData,
	require('./rooms/bowl-n-fun/venue-data').venueData,
	require('./rooms/brain-escape/venue-data').venueData,
	require('./rooms/brain-game-cph/venue-data').venueData,
	require('./rooms/breakout-room/venue-data').venueData,
	require('./rooms/city-bowling-holbaek/venue-data').venueData,
	require('./rooms/clockedin/venue-data').venueData,
	require('./rooms/countdown/venue-data').venueData,
	require('./rooms/countdown-escaperooms/venue-data').venueData,
	require('./rooms/down-the-rabbit-hole/venue-data').venueData,
	require('./rooms/escape-attic/venue-data').venueData,
	require('./rooms/escape-copenhagen/venue-data').venueData,
	require('./rooms/escape-denmark/venue-data').venueData,
	require('./rooms/escape-factory/venue-data').venueData,
	require('./rooms/escape-fyn/venue-data').venueData,
	require('./rooms/escape-game-odense/venue-data').venueData,
	require('./rooms/escape-games/venue-data').venueData,
	require('./rooms/escape-history-house/venue-data').venueData,
	require('./rooms/escape-room-randers/venue-data').venueData,
	require('./rooms/escape-room-roskilde/venue-data').venueData,
	require('./rooms/escape-room-tarm/venue-data').venueData,
	require('./rooms/escape-room-varde/venue-data').venueData,
	require('./rooms/escape-skagen/venue-data').venueData,
	require('./rooms/escape-team/venue-data').venueData,
	require('./rooms/escape-viborg/venue-data').venueData,
	require('./rooms/escape-zone/venue-data').venueData,
	require('./rooms/escapee/venue-data').venueData,
	require('./rooms/escapist/venue-data').venueData,
	require('./rooms/eventcamp-ertebjerggaard/venue-data').venueData,
	require('./rooms/fabriksevent/venue-data').venueData,
	require('./rooms/funcenter/venue-data').venueData,
	require('./rooms/froeslevlejrens-museum/venue-data').venueData,
	require('./rooms/garderhojfortet/venue-data').venueData,
	require('./rooms/getout/venue-data').venueData,
	require('./rooms/golf-and-fun-park/venue-data').venueData,
	require('./rooms/goodlock/venue-data').venueData,
	require('./rooms/great-escape/venue-data').venueData,
	require('./rooms/holbaek-museum/venue-data').venueData,
	require('./rooms/hotel-trapped/venue-data').venueData,
	require('./rooms/koldkrigsmuseum-stevnsfort/venue-data').venueData,
	require('./rooms/locked/venue-data').venueData,
	require('./rooms/middelaldercentret/venue-data').venueData,
	require('./rooms/midgaard-event/venue-data').venueData,
	require('./rooms/mosede-fort/venue-data').venueData,
	require('./rooms/museum-obscurum/venue-data').venueData,
	require('./rooms/mystery-makers/venue-data').venueData,
	require('./rooms/nerd-cave/venue-data').venueData,
	require('./rooms/penguin-escape/venue-data').venueData,
	require('./rooms/play-time/venue-data').venueData,
	require('./rooms/once-upon-a-time/venue-data').venueData,
	require('./rooms/questbox/venue-data').venueData,
	require('./rooms/riddle-house/venue-data').venueData,
	require('./rooms/riddle-room/venue-data').venueData,
	require('./rooms/royal-escapes/venue-data').venueData,
	require('./rooms/rubilees-escape-house/venue-data').venueData,
	require('./rooms/secret-games/venue-data').venueData,
	require('./rooms/timequest/venue-data').venueData,
	require('./rooms/vingsted/venue-data').venueData,
	require('./rooms/wayout-copenhagen/venue-data').venueData,
	require('./rooms/zagadka/venue-data').venueData,
];


export {venuesAndRooms};